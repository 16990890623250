<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:end>
                        <!-- <FileUpload
                mode="basic"
                accept="image/*"
                :maxFileSize="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block"
              />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>

                </Toolbar>



                <DataTable exportFilename="relatorio[ECONIMIA]" csvSeparator=";" ref="dt" :value="economia"
                    v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    :loading='loading'
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #loading> Carregando, aguarde... </template>
                    <template #empty> Nenhum resultado encontrado. </template>
                    <template #header>
                        <div class="
                        flex flex-column
                        md:flex-row md:justify-content-between md:align-items-center
                      ">
                            <h5 class="m-0">Relatório de Economia</h5>
                            <span class="">
                                <Dropdown class="mr-2" filter id="status" v-model="id_usina" :options="usinas" optionLabel="label"
                                    optionValue="value" placeholder="Filtrar por Usina">
                                </Dropdown>

                                <Dropdown class="mr-2" id="status" v-model="mes" :options="meses" optionLabel="label"
                                    optionValue="value" placeholder="Filtrar por Mês">
                                </Dropdown>

                                <Dropdown class="mr-2" id="status" v-model="ano" :options="anos" optionLabel="label"
                                    optionValue="value" placeholder="Filtrar por Ano">
                                </Dropdown>

                                <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm"
                                    @click="filtrar()" />

                            </span>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>

                        </div>

                    </template>

                    <Column v-for="col of colunas" :field="col.field" :header="col.header" :key="col.field"
                        style="white-space:nowrap">
                        <template #body="{ data, field }">
                            {{ field === 'price' ? formatCurrency(data[field]) : data[field] }}
                        </template>
                        <template #editor="{ data, field }">
                            <template v-if="field == 'usina'">
                                {{ data.usina }}

                            </template>
                            <template v-else-if="field == 'tipo_de_tensao'">
                                {{ data.tipo_de_tensao }}
                            </template>
                            <template v-else-if="field == 'concessionaria'">
                                {{ data.concessionaria }}
                            </template>
                            <template v-else>
                                <InputText v-model="data[field]" autofocus />
                            </template>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            ano: "",
            mes: "",
            usinas: [],
            anos: [
                { label: "", value: "" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" },{ label: "2024", value: "2024" }],
            meses: [
                { label: "", value: "" },
                { label: "Janeiro", value: "01" },
                { label: "Fevereiro", value: "02" },
                { label: "Março", value: "03" },
                { label: "Abril", value: "04" },
                { label: "Maio", value: "05" },
                { label: "Junho", value: "06" },
                { label: "Julho", value: "07" },
                { label: "Agosto", value: "08" },
                { label: "Setembro", value: "09" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            colunas: [
                { header: 'Mês Referência', field: 'Mes_Competencia' },
                { header: 'Identificação', field: 'identificacao' },
                { header: 'Código Cliente', field: 'codigo_cliente' },
                { header: 'Unidade', field: 'unidade' },
                { header: 'Rateio (%)', field: 'valor_rateio' },
                { header: 'Disponibilidade (kWh)', field: 'disponibilidade' },
                { header: 'Custo de Disponibilidade (kWh)', field: 'custo_disp' },
                { header: 'Leitura Anterior', field: 'Data_Leitura_Anterior' },
                { header: 'Leitura Atual', field: 'Data_Leitura_Atual' },
                { header: 'Geração Realizada (kWh)', field: 'geracao_realizada' },
                { header: 'Consumo Realizado (kWh)', field: 'Consumo_Registrado_kWh' },
                { header: 'Compensação 1 (kWh)', field: 'Energia_Compensada_kWh' }, // correção
                { header: 'Compensação 2 (kWh)', field: 'Energia_Compensada_2_kWh' },
                { header: 'Compensação 3 (kWh)', field: 'Energia_Compensada_3_kWh' },
                { header: 'Compensação Total (kWh)', field: 'compensasao_total' },
                { header: 'Saldo Atual (kWh)', field: 'Saldo_Atual_Geracao_Total' },
                { header: 'Consumo Faturado (kWh)', field: 'Consumo_Faturado_kWh' },
                { header: 'Consumo Faturado (R$)', field: 'Valor_Consumo_kWh_R' },
                { header: 'Energia Reativa (R$)', field: 'Energia_Reativa_Valor_' },
                { header: 'Subtotal Outros (R$)', field: 'subtotal' },
                { header: 'CIP (R$)', field: 'llumPubMunicipal_R' },
                { header: 'Adicional Bandeira (R$)', field: 'Adicional_Bandeira_R' },
                { header: 'Total Fatura c/ GD (R$)', field: 'total_fatura_gd_valor' },
                { header: 'Valor da Fatura (R$)', field: 'total_da_fatura' },

                { header: 'Tarifa Total (R$/MWh)', field: 'Tarifa_Consumo_kWh_com_impostos_R' },
                { header: 'Tarifa Compensada TE (R$/MWh)', field: 'tarifa_compensada_te' },
                { header: 'Tarifa Compensada TUSD (R$/MWh)', field: 'tarifa_compensada_tusd' },
                { header: 'Tarifa Total Compensada (R$/MWh)', field: 'tarifa_compensada_total' },
                { header: 'Valor da Energia Compensada (R$)', field: 'valor_energia_compensada' },
                { header: 'Valor da Conta s/ GD (R$)', field: 'valor_conta_sem_gd' },
                { header: 'Locação de Equipamento (R$)', field: 'locacao_equipamento' },
                { header: 'O&M (R$)', field: 'oem_valor' },
                { header: 'Demanda (R$)', field: 'demanda' },
                { header: 'Custo GD (R$)', field: 'custo_gd' },
                { header: 'Economia (%)', field: 'economia_porcentagem' },
                { header: 'Economia (R$)', field: 'economia_real' },

            ],
            token: localStorage.getItem('token'),
            dado_pendente: [
                { label: "Limpar Filtro", value: "" },
                { label: "Contrato RE", value: "1" },
                { label: "Inscrição Imobiliária", value: "2" },
                { label: "Ordem SAP", value: "3" },
                { label: "Endereço", value: "4" },
                { label: "Área", value: "5" },
                { label: "Tipo", value: "6" },
                { label: "Regional", value: "7" },
                { label: "Regra Pagamento", value: "8" },
            ],
            parcelas: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "7", value: 7 },
                { label: "8", value: 8 },
                { label: "9", value: 9 },
                { label: "10", value: 10 },
                { label: "11", value: 11 },
                { label: "12", value: 12 },
            ],
            dado: "",
            arquivo: "",
            economia: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            unidade: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            empresas: [],
            areas: [],
            regionais: [],
            tipos: [],
            estados: [],
            cidades: [],
            id_estado: "",
            id_cidade: "",
            id_empresa: "",
            id_unidade: "",
            id_usina: "",
            parcela: "",
            id_tipo: "",
            data_referencia: "",
            data_vencimento: "",
            total: "",
            boleto: "",
            faturas: [],
            loading: false,
        };
    },
    productService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarUsinas();
        //this.listarRelatorio();

    },
    methods: {
        listarAreas() {
            this.axios
                .post("/areas/listar")
                .then((response) => {
                    console.log(response);
                    let options = [];
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] };
                        options.push(option);
                    });
                    this.areas = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        trocarCidades() {
            console.log(this.unidade.id_estado);
            this.listarCidades(this.unidade.id_estado);
        },

        filtrar() {
            let dados = {
                id_usina: this.id_usina,
                ano: this.ano,
                mes: this.mes
            }
            this.loading = true;
            this.axios
                .post("/eum/economia", dados)
                .then((response) => {
                    if (Object.keys(response.data.dados).length == 0) {
                         this.economia = response.data.dados;
                    } else {
                        this.economia = response.data.dados;
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarUsinas() {

            this.axios
                .post("/usinas/listar")
                .then((response) => {

                    let options = [];

                    let res = response.data.dados;
                    Object.keys(res).forEach(ley => {
                        console.log(ley);
                        let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
                        options.push(option);
                    });


                    this.usinas = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        listarTipos() {
            this.axios
                .post("/tipos-faturas/listar")
                .then((response) => {
                    console.log(response);
                    let options = [];
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["titulo"] };
                        options.push(option);
                    });
                    this.tipos = options;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        listarRelatorio() {
            this.loading = true;
            this.axios
                .post("/eum/economia")
                .then((response) => {
                    this.economia = response.data.dados;
                    this.loading = false
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        formatCurrency(value) {
            if (value)
                return value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });
            return;
        },
        openNew() {
            this.unidade = {};
            this.submitted = false;
            this.productDialog = true;
        },
        hideDialog() {
            this.productDialog = false;
            this.submitted = false;
        },
        onChangeFileUpload() {
            this.arquivo = this.$refs.arquivo.files[0];
        },
        salvarFatura() {
            this.submitted = true;

            let formData = new FormData();
            formData.append("arquivo", this.arquivo);

            let dados = {
                id_empresa: this.id_empresa,
                id_unidade: this.id_unidade,
                id_tipo: this.id_tipo,
                data_referencia: this.data_referencia,
                data_vencimento: this.data_vencimento,
                total: this.total,
                boleto: this.boleto,
                parcela: this.parcela,
            };

            formData.append("dados", JSON.stringify(dados));

            if (this.unidade.id) {
                this.axios
                    .post("/unidades/editar", this.unidade)
                    .then(() => {
                        this.listarFatura();
                        this.productDialog = false;
                        this.unidade = {};
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            } else {
                this.axios
                    .post("/faturas/adicionar", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        this.listarFatura();
                        this.productDialog = false;
                        this.unidade = {};
                    })
                    .catch(() => {
                        self.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            }
        },
        download(fatura) {
            let url = { ...fatura }
            window.open(url.link + "/" + this.token);
        },
        editProduct(fatura) {
            this.productDialog = true;
            this.fatura = { ...fatura };
            this.id_empresa = this.fatura.id_empresa;
            this.id_unidade = this.fatura.id_unidade;
            this.id_tipo = this.fatura.id_tipo;
            this.data_referencia = this.fatura.data_referencia;
            this.data_vencimento = this.fatura.data_vencimento;
            this.total = this.fatura.total;
            this.boleto = this.fatura.boleto;
            this.parcela = this.fatura.parcela;
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            let obj = this.selectedProducts;
            Object.keys(obj).forEach((key) => {
                this.axios
                    .post("/unidades/apagar", obj[key])
                    .then(() => {
                        this.listarFatura();
                        this.deleteProductDialog = false;
                        this.unidade = {};
                        this.$toast.add({
                            severity: "success",
                            summary: "Sucesso",
                            detail: "Usário apagado com sucesso!s",
                            life: 3000,
                        });
                    })
                    .catch(() => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Ops!",
                            detail: "Chamar o T.I.",
                            life: 3000,
                        });
                    });
            });

            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Faturas apagada com sucesso!",
                life: 3000,
            });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
  